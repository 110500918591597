import {
  AssetType,
  CalculationCountry,
  DkTaxCode,
  Owner,
  PaymentFrequency,
  PensionCoverage,
  Policy,
} from '@dreamplan/autogenerated-clients/calculation';
import { TestProfileWithAnswers } from '@dreamplan/types';
import { sharedDefaultAnswers } from '../sharedProfileAnswers';

export const FrejaAndWilliamProfile: TestProfileWithAnswers = {
  name: 'Freja and William',
  answers: [
    ...sharedDefaultAnswers,
    {
      key: 'limitedCompanyDividends',
      value: 1111,
    },
    {
      key: 'limitedCompanySavingsTotal',
      value: 1250000,
    },
    {
      key: 'limitedCompanySavingsIncrease',
      value: 2300,
    },
    {
      key: 'userSelfEmploymentType',
      value: 'limitedCompany',
    },
    {
      key: 'isUserSelfEmployed',
      value: 'true',
    },
    {
      key: 'userCurrentPensionMonthlyPayment',
      value: 0,
    },
    {
      key: 'spouseCurrentPensionMonthlyPayment',
      value: 1480,
    },
    {
      key: 'userFullName',
      value: 'Freja',
    },
    {
      key: 'userAge',
      value: 1969,
    },
    {
      key: 'hasSpouse',
      value: 'true',
    },
    {
      key: 'spouseName',
      value: 'William',
    },
    {
      key: 'spouseAge',
      value: 55,
    },
    {
      key: 'userMonthlySalary',
      value: 19000,
    },
    {
      key: 'userCurrentPensionSavings',
      value: 5700000,
    },
    {
      key: 'spouseMonthlySalary',
      value: 37000,
    },
    {
      key: 'spouseCurrentPensionSavings',
      value: 1638000,
    },
    {
      key: 'houseAddress',
      value: 'test adresse',
    },
    {
      key: 'houseOwnershipType',
      value: 'Own',
    },
    {
      key: 'houseValue',
      value: 5000000,
    },
    {
      key: 'houseDebt',
      value: 2300000,
    },
    {
      key: 'houseDebtInterest',
      value: 0.02,
    },
    {
      key: 'houseDebtRemainingTenure',
      value: 10,
    },
    {
      key: 'houseDebtHasRepaymentFreeYears',
      value: 'true',
    },
    {
      key: 'houseDebtRemainingRepaymentFreeYears',
      value: 5,
    },
    {
      key: 'userMonthlySavingsAmount',
      value: 0,
    },
    {
      key: 'userCurrentEmergencySavings',
      value: 1900000,
    },
    {
      key: 'fetchPensionInformation',
      value: 'false',
    },
    {
      key: 'hasSecondHouse',
      value: 'false',
    },
    {
      key: 'userName',
      value: 'Freja',
    },
  ],
  expectedPayload: {
    primary: {
      birthYear: 1969,
      pensionAge: 69,
      monthlySalary: 19000,
      deathAge: undefined,
    },
    spouse: {
      birthYear: 1970,
      monthlySalary: 37000,
      pensionAge: 69,
      deathAge: undefined,
    },
    policies: [
      {
        id: 'dd5f84da-066d-4610-8753-4d4e9fc8960p',
        owner: Owner.Primary,
        coverages: [
          {
            type: 'PensionCoverage',
            id: 'dd5f84da-066d-4610-8753-4d4e9fc8960p',
            owner: Owner.Primary,
            initialValue: 5700000,
            taxCode: DkTaxCode.One,
            contribution: {
              paymentFrequency: PaymentFrequency.Monthly,
              amount: 0,
              isFromEmployer: undefined,
            },
            payOutType: {
              type: 'LifeAnnuity',
              payOutDate: undefined,
            },
          } as PensionCoverage,
        ],
      } as Policy,
      {
        id: 'dd5f84da-066d-4610-8753-4d4e9fc896sp',
        owner: Owner.Spouse,
        coverages: [
          {
            type: 'PensionCoverage',
            id: 'dd5f84da-066d-4610-8753-4d4e9fc896sp',
            owner: Owner.Spouse,
            initialValue: 1638000,
            taxCode: DkTaxCode.One,
            contribution: {
              paymentFrequency: PaymentFrequency.Monthly,
              amount: 1480,
              isFromEmployer: undefined,
            },
            payOutType: {
              type: 'LifeAnnuity',
              payOutDate: undefined,
            },
          } as PensionCoverage,
        ],
      } as Policy,
    ],
    housesWithLoans: [
      {
        id: 'dd5f84da-066d-4610-8753-4d4e9fc8960f',
        houseValue: 5000000,
        type: 'HouseWithLoans',
        loans: [
          {
            id: 'd9497ef5-9d8a-4c03-8308-cbee6746339c',
            remainingPrincipal: 2300000,
            type: 'AmortizedLoan',
            contributionFeeRate: 0.006999999999999999,
            interestRate: 0.0002,
            redemptionDetails: {
              // bondPrice: 0,
              redemptionFee: 5000,
            },
            remainingRepaymentFreeYears: 5,
            remainingTenure: 10,
          },
        ],
      },
    ],
    liquidAssets: [
      {
        id: 'dd5f84da-066d-4610-8753-4d4e9fc8960h',
        assetType: AssetType.Stocks,
        initialValue: 1900000,
        monthlyDeposit: undefined,
      },
    ],
    municipalityId: undefined,
    dreams: undefined,
    calculationCountry: CalculationCountry.Dk,
    companies: [
      {
        id: 'bebc961c-712a-458f-87cf-434b2ad64325',
        type: 'IncorporatedCompany',
        yearlyEbitda: 27600,
        dividends: 1111,
        initialEquity: 1250000,
      },
    ],
    recommendationSettings: {
      companyRecommendation: undefined,
      primaryPensionRecommendation: undefined,
      spousePensionRecommendation: undefined,
      emergencySavingRecommendation: undefined,
      loanRecommendation: {
        type: 'RestrictedLoanToValueRatioGoalSeek',
        ratio: undefined,
        establishmentDetails: {
          foundationFee: 20000,
          // bondPrice: 98,
        },
        // interestRate: 0.06,
        // contributionFeeRate: 0.02,
      },
    },
  },
  expectedResult: undefined,
};
