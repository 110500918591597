import * as React from 'react';
import {
  useHover,
  useFloating,
  useInteractions,
  safePolygon,
  offset,
  shift,
  useTransitionStyles,
} from '@floating-ui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { PageLinkType } from '../../types/CommonTypes';
import PageLink from '../PageLink';
import { Text } from '../Typography';

interface Props {
  className?: string;
  links?: PageLinkType[];
}

const NavMenu = ({ link }: { link: PageLinkType }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom',
    middleware: [offset(5), shift({ padding: 8 })],
  });
  const hover = useHover(context, {
    handleClose: safePolygon({
      requireIntent: false,
    }),
  });

  const { isMounted, styles } = useTransitionStyles(context, {
    duration: { open: 300, close: 300 },
    initial: { opacity: 0, transform: 'scaleY(0)', transformOrigin: 'top' },
    open: {
      opacity: 1,
      transform: 'scaleY(1)',
      transformOrigin: 'top',
      boxShadow: '0 10px 15px -3px rgba(0,0,0,0.1)',
    },
    close: { opacity: 0, transform: 'scaleY(0)', transformOrigin: 'top' },
  });

  const { getFloatingProps, getReferenceProps } = useInteractions([hover]);
  return (
    <li className="relative">
      <div
        className="flex cursor-pointer items-center"
        {...getReferenceProps()}
        ref={refs.setReference}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text variant={'large'} className="text-tertiary">
          {link.page}
        </Text>
        <ChevronDownIcon
          className={clsx(
            'ml-4 flex h-5 w-4 items-center stroke-2 pt-1 transition-transform duration-300',
            isOpen ? 'rotate-180' : 'rotate-0',
          )}
          stroke="#000"
        />
      </div>
      {isMounted && (
        <ul
          {...getFloatingProps()}
          ref={refs.setFloating}
          style={{
            ...styles,
            ...floatingStyles,
            transform: `${floatingStyles.transform} scaleY(${
              styles.transform === 'scaleY(0)' ? 0 : 1
            })`,
          }}
          className="absolute z-50 mt-2 w-48 overflow-hidden rounded-b border border-t-black bg-white pb-4 pt-4"
        >
          {link.subNavigation?.map((subLink) => (
            <li
              key={subLink.navigation}
              className="hover:bg-opacity/70 cursor-pointer px-4 py-2 text-lg"
            >
              <PageLink link={subLink} className="text-lg" />
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

const MainNavigation = ({ className = '', links = [] }: Props) => {
  // FIXME: it not a good practice to access context provider in core component directly
  return (
    <nav className={`flex ${className}`}>
      <ul className="flex space-x-10">
        {links?.map((link) => {
          if (link.subNavigation) {
            return <NavMenu key={link.navigation} link={link} />;
          }
          return (
            <li key={link.navigation}>
              <PageLink link={link} className="text-lg" />
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default MainNavigation;
